@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/genty-demo");
@import url("https://fonts.cdnfonts.com/css/montserrat");

body {
  background-image: url(./assets/blueseabgg.svg) !important;
  background-position: bottom !important;
  background-size: cover !important;
  font-family: "Genty Demo", sans-serif;
}

.hero {
  background-image: url(./assets/skybg.svg) !important;
  background-position: center !important;
  background-size: cover !important;
}

.crabbynomnom {
  background-image: url(./assets/oceanbg.svg) !important;
  background-position: bottom !important;
  background-size: cover !important;
}

.bluesea {
  background-image: url(./assets/blueseabgg.svg) !important;
  background-position: bottom !important;
  background-size: cover !important;
}

.monts {
  font-family: "Montserrat", sans-serif;
}

.helpmegod {
  left: 0 !important;
  top: 0 !important;
  position: relative !important;
}

#helpmegod {
  left: 0 !important;
  top: 0 !important;
  position: relative !important;
}

.contract-address-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.contract-address-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contract-address-button:hover {
  background-color: #45a049;
}
